/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({
  description,
  lang,
  meta,
  title,
  image,
  metaTitleFacebook,
  metaImageFacebook,
  metaDescFacebook,
  metaTitleTwitter,
  metaImageTwitter,
  metaDescTwitter
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={
        title === "Home"
          ? `${site.siteMetadata.title}`
          : `%s | ${site.siteMetadata.title}`
      }
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: metaTitleFacebook || `${title} | Each. Step. Home.`
        },
        {
          property: `og:description`,
          content: metaDescFacebook || metaDescription
        },
        {
          property: "og:image",
          content: metaImageFacebook
            ? metaImageFacebook
            : image
            ? image.og_frontpage_image
            : ""
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: metaTitleTwitter || `${title} | Each. Step. Home.`
        },
        {
          name: `twitter:description`,
          content: metaDescTwitter || metaDescription
        },
        {
          name: `twitter:image`,
          content:
            metaImageTwitter || metaImageFacebook
              ? metaImageTwitter || metaImageFacebook
              : ""
        }
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default SEO;
