import tw, { styled } from 'twin.macro';

const LabelAndInput = styled.div`
  transition: all 0.2s ease-out;
  position: relative;

  ${tw`
    px-2 pb-4 last:pb-0
  `};

  &.hide {
    height: 0;
    opacity: 0;
    margin: 0 !important;
    transition: none;
    padding-bottom: 0;
  }

  &.error .error-text {
    display: block;
  }

  &.error input,
  &.error select,
  &.error textarea {
    border-bottom: 1px solid red;
  }

  @media (min-width: 768px) {
    &.first-name-field,
    &.last-name-field {
      width: 50%;
    }

    &.first-name-field {
      padding-right: 0.5rem;
    }

    &.last-name-field {
      padding-left: 0.5rem;
    }

    // &:not(:first-child) {
    //   margin-top: 40px;
    // }

    &.state-address-field {
      margin-top: 0;
    }
  }
`;

export default LabelAndInput;