import { styled } from 'twin.macro';

const DisclaimerText = styled.p`
  text-align: center;
  color: var(--color-grey);

  font-size: 12px;
  line-height: 19px;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 26px;
    letter-spacing: -0.16px;
  }
`;

export default DisclaimerText;