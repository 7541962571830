import React, { useState, useEffect, useContext } from "react";
import xss from "xss";
import { AsYouType } from "libphonenumber-js";

import Context from "../../../context/Context";
import uniqueId from "../../../utils/uniqueId";

/**
 * Styled Components
 */
import Form from "./Form";
import FormTitle from "./FormTitle";
import Subtitle from "./Subtitle";
import FieldSet from "./FieldSet";
import Input from "./Input";
import SubmitButton from "./SubmitButton";
import DisclaimerText from "./DisclaimerText";
import SuccessText from "./SuccessText";
import FieldsBySections from "./FieldsBySections";
import Fields from "./Fields";

const RenderFields = props =>
  props.hasSections ? <FieldsBySections {...props} /> : <Fields {...props} />;

/**
 * Gravity Form
 */
const GFForm = ({
  id,
  data,
  hasTitle,
  hasDescription,
  hasFooter,
  hasSections,
  submitText,
  clearOnSuccess
}) => {
  const { title, description, confirmations, formFields: field_array } = data;

  const { formValues, setFormValues } = useContext(Context);
  const [success, setSuccess] = useState(false);
  const [successMessage] = useState(confirmations[0].message);
  const [errors, setErrors] = useState({});
  const [formUniqueId] = useState(() => uniqueId("form-"));
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    // check if the form data already exists
    if (`gf_${id}` in formValues) return;

    // Set the intial field state
    let initialFieldState = {};
    field_array.forEach((field, index) => {
      const conditional = JSON.parse(field.conditionalLogic);
      const isHidden = conditional && conditional.actionType === "show";

      // determine if this is a top level field
      // or a sub field
      if (field.inputs) {
        field.inputs.forEach(input => {
          if (!input.isHidden) {
            initialFieldState[`${field.type}_${input.id}`] = {
              value: "",
              isRequired: field.isRequired,
              conditional,
              isHidden
            };
          }
        });
      } else {
        initialFieldState[`${field.type}_${field.id}`] = {
          value: "",
          isRequired: field.isRequired,
          conditional,
          isHidden
        };
      }
    });

    setFormValues({
      ...formValues,
      [`gf_${id}`]: initialFieldState
    });

    return () => (initialFieldState = {});
  }, [formValues]);

  /**
   * Functions
   */
  const handleInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    const currentValues = { ...formValues[`gf_${id}`] };
    if (e.target.type === "tel") {
      currentValues[name].value = new AsYouType("US").input(value);
    } else if (e.target.type === "checkbox") {
      currentValues[name].value = value;
      currentValues[name].checked = !currentValues[name].checked;
    } else {
      currentValues[name].value = value;
    }

    // check conditions
    // loop through all fields and find the field that
    // is affected by the value of the current change event
    let conditional_field, conditional_field_id;
    for (let [key, value] of Object.entries(formValues[`gf_${id}`])) {
      // check if the field has a conditional
      if (value.conditional) {
        // if it does, check it the conditional rules apply
        // by comparing ids
        if (
          value.conditional.rules.some(
            rule => +rule.fieldId === +name.substr(name.lastIndexOf("_") + 1)
          )
        ) {
          // store the field and id
          conditional_field = value;
          conditional_field_id = key;
        }
      }
    }
    if (conditional_field) {
      let condition;
      const { actionType, logicType, rules } = conditional_field.conditional;

      if (logicType === "all") {
        // check if every rule is satisfied
        rules.every(rule => {
          // extract all necessary info
          const { operator, value: rule_value } = rule;

          // Rule check. For now only 'is' and 'is not' operators are being checked
          if (operator === "is") {
            condition = rule_value === value;
            currentValues[conditional_field_id].isHidden =
              actionType === "show" ? !condition : condition;
          } else if (operator === "is not") {
            condition = rule_value !== value;
            currentValues[conditional_field_id].isHidden =
              actionType === "show" ? condition : !condition;
          } else if (operator === ">") {
            condition = value > rule_value;
            currentValues[conditional_field_id].isHidden =
              actionType === "show" ? !condition : condition;
          } else if (operator === "<") {
            condition = value < rule_value;
            currentValues[conditional_field_id].isHidden =
              actionType === "show" ? !condition : condition;
          }
          return condition;
        });
      }
    }

    setFormValues({
      ...formValues,
      [`gf_${id}`]: currentValues
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setButtonDisabled(true);
    let data = {};
    let errors = {};
    for (const field in formValues[`gf_${id}`]) {
      // Check for errors.
      // if the field is required, does not have a value, and
      // is not hidden, it's an error
      if (
        formValues[`gf_${id}`][field].isRequired &&
        !formValues[`gf_${id}`][field].value &&
        !formValues[`gf_${id}`][field].isHidden
      ) {
        errors[field] = true;
      }

      // Prepare the data
      const field_id = field
        .substr(field.lastIndexOf("_") + 1)
        .replace(".", "_");
      data[`input_${field_id}`] = xss(formValues[`gf_${id}`][field].value);
    }

    data.form_id = id;

    // if any errors cancel
    if (Object.values(errors).some(error => error)) {
      console.log(errors);
      setErrors(errors);
      setButtonDisabled(false);
      return;
    }

    const response = await fetch(`/api/formSubmit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    const json = await response.json();

    if (response.ok) {
      // temporary response
      if (json?.json?.code === "rest_forbidden") {
        console.error(`ERROR: ${json.json.message}`);
      } else {
        if (process.env.NODE_ENV === "production") {
          window.dataLayer.push({ event: title + " Form Submitted" });
        }
        setSuccess(true);

        delete formValues[`gf_${id}`];
        setFormValues({
          ...formValues
        });
      }
    }
  };

  // On error, scroll to the first field with an error
  useEffect(() => {
    if (Object.keys(errors).length) {
      const first_error = document.querySelector(
        `form#${formUniqueId} [name="${Object.keys(errors)[0]}"]`
      );
      if (first_error) {
        // some reason this fails in production so puttin a check here for now
        first_error.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [errors]);

  if (success && clearOnSuccess) {
    return (
      <SuccessText
        className="success-text"
        dangerouslySetInnerHTML={{ __html: successMessage }}
      />
    );
  }

  if (!(`gf_${id}` in formValues)) return null;

  return (
    <Form id={formUniqueId} onSubmit={handleSubmit}>
      {hasTitle && <FormTitle>{title}</FormTitle>}

      {hasDescription && <Subtitle>{description}</Subtitle>}

      <RenderFields
        hasSections={hasSections}
        field_array={field_array}
        errors={errors}
        formValues={formValues[`gf_${id}`]}
        handleInput={handleInput}
      />

      <Input type="hidden" name="form_id" value={id} />

      {!hasFooter && (
        <>
          <SubmitButton
            type="submit"
            className="submit-button"
            disabled={buttonDisabled}
          >
            {submitText || "Submit"}
          </SubmitButton>
          {success && (
            <SuccessText
              className="success-text"
              dangerouslySetInnerHTML={{ __html: successMessage }}
            />
          )}
        </>
      )}

      {hasFooter && (
        <FieldSet className="footer">
          <SubmitButton
            type="submit"
            className="submit-button"
            disabled={buttonDisabled}
          >
            {submitText || "Submit"}
          </SubmitButton>
          <DisclaimerText>
            After you submit, a member of our team will review your information
            and follow up with you soon.
          </DisclaimerText>
          {success && (
            <SuccessText className="success-text">{successMessage}</SuccessText>
          )}
        </FieldSet>
      )}
    </Form>
  );
};

export default GFForm;
