import { styled } from 'twin.macro';

const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  background-color: rgba(243, 244, 245, 0.5);
  border: 2px solid rgba(124, 135, 149, 0.3);
  height: 10ch;
  font-family: inherit;
  padding: 5px;
  font-size: 12px;
  line-height: 14px;

  @media (min-width: 768px) {
    height: 169px;
    margin-top: 10px;

    font-size: 15px;
    line-height: 18px;
  }
`;

export default TextArea;