import React from "react";
import tw, { styled } from "twin.macro";
import { ReactComponent as ArrowDiagonal } from "../../images/ICON_GREY_ARROWDIAGONAL.svg";
import { ReactComponent as ArrowUp } from "../../images/ICON_GREY_ARROWUP.svg";
import { ReactComponent as ArrowDown } from "../../images/ICON_GREY_ARROWDOWN.svg";
import { ReactComponent as Play } from "../../images/ICON_GREY_PLAY.svg";
import { ReactComponent as Plus } from "../../images/ICON_GREY_PLUS.svg";
import { ReactComponent as Close } from "../../images/ICON_GREY_CLOSE.svg";
import { ReactComponent as Search } from "../../images/ICON_GREY_SEARCH.svg";
import { ReactComponent as Menu } from "../../images/ICON_GREY_MENU.svg";

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  font-size: 11px;
  letter-spacing: 0.25px;
  cursor: pointer;
  font-family: attribute-mono, monospace;
  text-transform: uppercase;
  max-width: max-content;

  svg {
    fill: ${({ color }) => (color ? color : "")};
  }

  &.light,
  &.dark,
  &.feature {
    text-align: center;
    padding: 8px 20px;
    border-radius: 32.89px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &.light {
    background-color: transparent;
    color: var(--color-offWhite);
    border: 2.27px solid var(--color-offWhite);
    transition: background-color 0.5s ease, color 0.5s ease, filter 0.5s ease;

    &:hover {
      background-color: var(--color-offWhite);
      color: var(--color-offBlack);
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    }
  }

  &.dark,
  &.feature,
  &.minimal {
    transition: opacity 0.5s ease, filter 0.5s ease;

    &:hover {
      opacity: 0.5;
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    }
  }

  &.dark,
  &.feature {
    color: var(--color-offWhite);
  }
  &.dark {
    ${tw`bg-darkBlue border-2 border-darkBlue`}
  }

  &.feature {
    background-color: var(--color-magenta);
    border: 2.27px solid var(--color-magenta);
  }
  &.minimal {
    background-color: transparent;
    padding: 0;
    border: 0;
    border-bottom: 1px solid var(--color-${({ color }) => (color ? color : "vibrantBlue")});
    color: var(--color-${({ color }) => (color ? color : "vibrantBlue")});
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.07px;
    width: fit-content;
    margin: 0 auto;

    svg {
      fill: var(--color-${({ color }) => (color ? color : "vibrantBlue")});
      height: 8px;
      width: 8px;
      margin-right: 5px;
    }
  }

  &.donate_learn_more {
    border-bottom: 1px solid #fff;
    color: #fff;
    margin-top: 58px;
  }

  &.button_icon {
    border: none;
    background-color: transparent;
    padding: 0;

    svg {
      width: 15px;
      height: 16px;
    }
  }
  /* Tablet */
  @media (min-width: 768px) {
    font-size: 15px;
    letter-spacing: 0.34px;

    &.light,
    &.dark,
    &.feature {
      padding: 10px 25px;
    }

    &.minimal {
      font-size: 17px;
      line-height: 24px;
      letter-spacing: 0.1px;

      svg {
        height: 11px;
        width: 11px;
        margin-right: 8px;
      }
    }

    &.donate_learn_more {
      margin-top: 88px;
    }

    &.button_icon {
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 19px;

    &.light,
    &.dark,
    &.feature {
      letter-spacing: 0.43px;
      height: auto;
      padding: 11.5px 31.5px;
    }
    &.minimal {
      font-size: 19px;
      line-height: 27px;
      letter-spacing: 0.11px;

      svg {
        height: 12px;
        width: 12px;
        margin-right: 10px;
      }
    }

    &.donate_learn_more {
      margin-top: 158px;
    }

    &.button_icon {
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  &.socials {
    ${tw`
      rounded
    `};

    padding: 0;
    border-width: 2px;
    min-width: 3rem;
    width: 3rem;
    height: 3rem;
    margin: 0 0.5rem;
    text-alignt: center;
    border-radius: 50%;
    line-height: calc(3rem - 4px);

    a {
      width: auto;
      margin: 0 auto;
      font-size: 1.5rem;
      padding: 0;
      text-alignt: center;
    }

    svg {
      path,
      rect,
      circle {
        transition: all 0.5s ease;
      }
    }

    &:hover {
      a svg {
        path,
        rect {
          stroke: var(--color-magenta);
        }

        circle {
          stroke: var(--color-magenta);
        }
      }
    }
  }
`;

const ButtonComponent = ({ style, color, children, onClick }) => {
  let icon;
  switch (style) {
    case "icon_arrow_up":
    case "minimal_arrow_up":
      icon = <ArrowUp />;
      break;
    case "minimal_arrow_diagonal":
    case "icon_arrow_diagonal":
      icon = <ArrowDiagonal />;
      break;
    case "minimal_arrow_down":
    case "donate_learn_more":
    case "icon_arrow_down":
      icon = <ArrowDown />;
      break;
    case "minimal_play":
    case "icon_play":
      icon = <Play />;
      break;
    case "minimal_plus":
    case "icon_plus":
      icon = <Plus />;
      break;
    case "minimal_close":
    case "icon_close":
      icon = <Close />;
      break;
    case "minimal_search":
    case "icon_search":
      icon = <Search />;
      break;
    case "icon_menu":
      icon = <Search />;
      break;
    default:
      break;
  }
  return (
    <Button
      className={`${style} ${style.includes("minimal") ? " minimal" : ""} ${
        style.includes("icon") ? "  button_icon" : ""
      }`}
      color={color}
      onClick={onClick}
    >
      {icon}
      {children}
    </Button>
  );
};

export default ButtonComponent;
