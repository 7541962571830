import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import tw, { styled } from "twin.macro";
import Button from "../PageComponents/Button";

const MobileNav = styled.nav`
  ${tw`
    fixed top-0 left-0 right-0 bottom-0 z-20 px-6 py-12
  `}

  background-color: var(--color-magenta);
  font-weight: 700;
  transform: translate(100%, 0);
  transition: transform 0.5s ease;

  &.open {
    transform: translate(0, 0);
  }
`;

const MobileNavHeader = styled.header`
  ${tw`
    bg-transparent flex flex-row items-center justify-between
  `}
`;

const HeaderTitle = styled(Link)`
  ${tw`
     uppercase no-underline text-white
  `}
`;

const CloseButton = styled.button`
  ${tw`
     bg-transparent p-0 rounded-none border-0
  `}

  svg {
    stroke: white;
  }
`;

const List = styled.ul`
  ${tw`
     list-none space-y-3 mt-10
  `};

  @media (min-width: 640px) {
    ${tw`space-y-5`}
  }
`;

const ListItem = styled.li`
  ${tw` block hover:underline text-4xl`}

  @media (min-width: 640px) {
    ${tw`text-6xl`}
  }

  a {
    ${tw`
     no-underline text-white leading-10
    `}

    &:hover {
      color: ${props => props.hover};
    }
  }
`;

const MobileNavComponent = ({ open, list, onClick }) => {
  // run graphql for navigation here

  return (
    <MobileNav className={open ? "open" : ""}>
      <MobileNavHeader>
        <HeaderTitle>Each. Step. Home</HeaderTitle>
        <Button style="icon_close" color="#fff" onClick={onClick}></Button>
      </MobileNavHeader>
      {list && (
        <List>
          {list.map((nav, index) => (
            <ListItem key={index}>
              <Link activeClassName={"active"} to={`/${nav.slug}/`}>
                {nav.title}
              </Link>
            </ListItem>
          ))}
        </List>
      )}
    </MobileNav>
  );
};
export default MobileNavComponent;
