import { styled } from 'twin.macro';

const SubmitButton = styled.button`
  &[disabled] {
    cursor: not-allowed;
  }

  &:hover:not([disabled]) {
    opacity: 0.5;
  }

  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    font-size: 19px;
  }
`;

SubmitButton.defaultProps = { disabled: props => props.disabled };

export default SubmitButton;