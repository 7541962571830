import tw, { styled } from 'twin.macro';

const AddressField = styled.div`
  ${tw`
    flex flex-wrap
  `};
  position: relative;
  // margin-top: 25px;
  transition: all 0.2s ease-out;

  &.hide {
    height: 0;
    opacity: 0;
    margin: 0 !important;
    transition: none;
  }

  @media (min-width: 768px) {
    // width: 70%;
    // margin-top: 40px;
  }

  .address-address-field {
    ${tw`
      md:w-full px-2
    `};
  }

  .city-address-field {
    ${tw`
      md:w-1/2 px-2
    `};
  }

  .state-address-field,
  .zip-address-field {
    ${tw`
      md:w-1/4 px-2
    `};
  }
`;

export default AddressField;