import { styled } from "twin.macro";

const FormModalBody = styled.div`
  background-color: #ffffff;
  position: relative;
  z-index: 10;
  padding: 40px 0;

  @media (min-width: 768px) {
    padding: 60px 0;
  }

  @media (min-width: 1024px) {
    max-width: 850px;
    margin: 0 auto;
    padding: 60px 0;
  }
`;

export default FormModalBody;