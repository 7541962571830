import React, { useState, useEffect, useContext } from "react";
import Context from "../context/Context";
import { useStaticQuery, graphql, Link } from "gatsby";
import tw, { styled } from "twin.macro";
import Logo from "../images/logos/STACKED_LIGHT.svg";
import { ReactComponent as ArrowRight } from "../images/ICON_GREY_ARROWRIGHT.svg";
import SocialIcon from "./PageComponents/SocialIcon";
import GFForm from "./PageComponents/Forms/GFForm";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Footer = styled.footer`
  background-color: var(--color-offBlack);
`;

const InnerContent = styled.section`
  color: var(--color-offWhite);
  padding: 52px 48px 60px;

  ${tw`
    relative
  `};

  @media (min-width: 768px) {
    padding: 90px 118px;
  }

  @media (min-width: 1024px) {
    padding: 85px 80px 85px 137px;
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const Contact = styled.section`
  @media (min-width: 1024px) {
    width: 33.33%;
  }
`;

const FooterLogo = styled.img`
  width: 72px;
  height: 41px;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    width: 136px;
    height: 77px;
    margin-bottom: 28px;
  }
`;

const Description = styled.p`
  font-family: attribute-mono, monospace;
  font-weight: 300;
  font-size: 9px;
  line-height: 15px;
  letter-spacing: -0.09px;
  margin-bottom: 20px;

  ${tw`
    uppercase
  `};

  @media (min-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.12px;
    margin-bottom: 28px;
  }

  @media (min-width: 1024px) {
    max-width: 70ch;
  }
`;

const FooterNav = styled.nav`
  margin-bottom: 26px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    width: 33.333%;
  }
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const ListItem = styled.li`
  a {
    color: inherit;
    text-decoration: none;
  }
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.12px;
  width: 33.333%;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 29px;
    letter-spacing: -0.17px;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }
`;

const FormAndContact = styled.aside`
  @media (min-width: 1024px) {
    width: calc(33% - 20px);
  }

  form {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }

  form > * {
    padding: 0 0.5rem;
  }

  form > * {
    margin-bottom: 1rem;
  }

  form .first-name-field,
  form .last-name-field,
  form .address-field,
  form .phone-field {
    width: 50%;
  }

  form .zip-address-field,
  form .email-field {
    width: 100%;
  }

  form .submit-button {
    position: absolute;
    bottom: 0;
    right: 0;
    fill: #fff;
    width: 2rem;
    height: 2rem;
    margin-bottom: 1.5rem;
    padding-bottom: 6px;
    background: none;
  }

  @media (min-width: 640px) {
    form .phone-field,
    form .address-field {
      width: 25%;
    }

    form .email-field {
      width: 50%;
    }
  }

  @media (min-width: 1024px) {
    form .first-name-field,
    form .last-name-field,
    form .address-field,
    form .phone-field,
    form .email-field {
      margin-top: 10px;
    }

    form .first-name-field,
    form .last-name-field {
      margin-top: 0;
    }

    form .phone-field,
    form .address-field {
      width: 50%;
    }

    form .email-field {
      width: 100%;
    }
  }

  .success-text {
    color: #fff;
  }
`;

const ContactNav = styled.nav`
  display: flex;
  margin-top: 2rem;
`;

const ContactButton = styled.button`
  ${tw`
      bg-transparent
  `};
  font-family: attribute-mono, monospace;
  border: 1px solid #fff;
  text-align: center;
  text-transform: uppercase;
  color: #fff;

  border-radius: 13px;
  width: 75px;
  height: 20px;
  font-size: 8px;
  letter-spacing: -0.08px;
  transition: background-color 0.25s ease, color 0.25s ease;
  cursor: pointer;

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    background-color: #fff;
    color: var(--color-offBlack);
  }

  @media (min-width: 768px) {
    border-radius: 19.73px;
    width: 114px;
    height: 30px;
    font-size: 12px;
    letter-spacing: -0.12px;
  }
`;

const Copyright = styled.small`
  color: #7c8795;
  font-family: attribute-mono, monospace;
  font-size: 9px;
  font-weight: 300;
  letter-spacing: -0.09px;
  line-height: 13px;
  text-align: center;
  left: 20px;
  top: 0;
  bottom: 0;
  writing-mode: vertical-rl;
  transform: rotate(180deg);

  ${tw`
      absolute
  `}

  @media (min-width: 768px) {
    left: 63px;
    font-size: 13.8px;
    line-height: 20px;
    letter-spacing: -0.13px;
  }
`;

const FooterComponent = props => {
  const { allWordpressMenusMenusItems, allGfForm } = useStaticQuery(graphql`
    {
      allWordpressMenusMenusItems(filter: { slug: { eq: "footer" } }) {
        nodes {
          slug
          items {
            title
            slug
          }
        }
      }
      allGfForm(filter: { formId: { eq: 1 } }) {
        edges {
          node {
            formId
            title
            description
            slug
            apiURL
            confirmations {
              id
              name
              isDefault
              type
              message
              url
              queryString
            }
            formFields {
              type
              label
              placeholder
              cssClass
              id
              inputs {
                name
                label
                inputType
                choices {
                  text
                  value
                }
                isHidden
                id
                placeholder
              }
              isRequired
              addressType
              checkboxLabel
              choices
              conditionalLogic
              defaultValue
            }
          }
        }
      }
    }
  `);

  const { setModal } = useContext(Context);
  const [successMessage] = useState(
    allGfForm.edges[0]?.node?.confirmations[0]?.message
  );
  const [navList] = useState(allWordpressMenusMenusItems?.nodes[0].items);

  // console.log(allWordpressMenusMenusItems)

  return (
    <Footer>
      <InnerContent>
        <Contact>
          <FooterLogo src={Logo} />
          <Description>
            EACH STEP HOME, FORMERLY EACH STEP HOME, IS A PROJECT OF SOCIAL AND ENVIRONMENTAL 
            ENTREPRENEURS (SEE), A REGISTERED PUBLIC CHARITY, WHICH PROVIDES NON-PROFIT STATUS. 
            TAX ID 95-4116679.
          </Description>
        </Contact>
        <FooterNav>
          <List>
            {navList.map((nav, index) => (
              <ListItem key={index}>
                <Link to={`/${nav.slug}/`}>{nav.title}</Link>
              </ListItem>
            ))}
          </List>
        </FooterNav>
        <FormAndContact>
          <GFForm
            id={1}
            data={allGfForm.edges[0].node}
            submitText={<ArrowRight />}
            clearOnSuccess={true}
          />
          <ContactNav>
            <SocialIcon icon="facebook" />
            <SocialIcon icon="instagram" />
            <SocialIcon icon="twitter" />
            {/* <SocialButton>
              <a
                href="https://www.facebook.com/EveryLastOne1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook />
              </a>
            </SocialButton>
            <SocialButton>
              <a
                href="https://www.instagram.com/everylast1/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram />
              </a>
            </SocialButton>
            <SocialButton>
              <a
                href="https://twitter.com/everylastone1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter />
              </a>
            </SocialButton> */}
            <ContactButton>
              <AnchorLink to="/about#contact">Contact</AnchorLink>
            </ContactButton>
          </ContactNav>
        </FormAndContact>
        <Copyright>© {new Date().getFullYear()}</Copyright>
      </InnerContent>
    </Footer>
  );
};

export default FooterComponent;
