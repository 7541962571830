import tw, { styled } from "twin.macro";

const Subtitle = styled.p`
  ${tw`max-w-2xl px-4`}
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.27px;
  margin: 0 auto 40px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 29px;
    letter-spacing: -0.36px;
    margin: 0 auto 60px;
  }

  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 34px;
    line-height: -0.34px;
  }
`;

export default Subtitle;