import React, { useEffect } from "react";

/**
 * Form Styled Components
 */
import SectionTitle from "./SectionTitle";
import AddressField from "./AddressField";
import LabelAndInput from "./LabelAndInput";
import Label from "./Label";
import Input from "./Input";
import Select from "./Select";
import Option from "./Option";
import TextArea from "./TextArea";
import CheckboxSection from "./CheckboxSection";
import CheckboxFieldSet from "./CheckboxFieldSet";
import CheckboxLabel from "./CheckboxLabel";
import Checkbox from "./Checkbox";
import RadioSection from "./RadioSection";
import RadioFieldSet from "./RadioFieldSet";
import RadioLabel from "./RadioLabel";
import Radio from "./Radio";
import ErrorText from "./ErrorText";

import US_States from "../../../utils/US_States";

/**
 * This component will render input fields
 * based on the type defined by Gravity Forms
 */
const GFField = ({ field, id, value, handleInput, errors, isHidden }) => {
  const { type, label } = field;

  const renderField = field => {
    switch (field.type) {
      /**
       * Section header
       */
      case "section":
        return <SectionTitle>{field.label}</SectionTitle>;
      /**
       * Name Field
       */
      case "name":
        return field.inputs.map((input, index) => {
          if (!input.isHidden) {
            const sub_label = input.label.toLowerCase();
            const sub_id = input.id;
            return (
              <LabelAndInput
                key={sub_id}
                className={`${sub_label}-${type}-field${
                  errors[`${type}_${sub_id}`] ? " error" : ""
                }${isHidden ? " hide" : ""} ${field.cssClass}`}
              >
                <Label
                  htmlFor={`${sub_label}_${id}`}
                  className={`${sub_label}-${type}-label`}
                >
                  {input.label} Name{field.isRequired && "*"}
                </Label>
                <Input
                  type="text"
                  name={`${type}_${sub_id}`}
                  className={`${sub_label}-${type}-input`}
                  id={`${sub_label}_${id}`}
                  value={value[index]}
                  placeholder={input.placeholder}
                  onChange={handleInput}
                  data-required={field.isRequired}
                />
                <ErrorText className="error-text">
                  You must complete this field
                </ErrorText>
              </LabelAndInput>
            );
          }
        });
      /**
       * Number Field
       */
      case "number":
        // return field.inputs.map((input, index) => {
        if (!isHidden) {
          return (
            <LabelAndInput
              className={`${type}-field${errors[id] ? " error" : ""}${
                isHidden ? " hide" : ""
              } ${field.cssClass}`}
            >
              <Label htmlFor={id} className={`${type}-label`}>
                {label}
                {field.isRequired && "*"}
              </Label>
              <Input
                type="number"
                name={`${id}`}
                className={`${type}-input`}
                id={`${id}`}
                value={value}
                placeholder={field.placeholder}
                onChange={handleInput}
                data-required={field.isRequired}
              />
              <ErrorText className="error-text">
                You must complete this field
              </ErrorText>
            </LabelAndInput>
          );
        }
      // })
      /**
       * Email Field
       */
      case "email":
        return (
          <LabelAndInput
            className={`${type}-field${errors[id] ? " error" : ""}${
              isHidden ? " hide" : ""
            } ${field.cssClass}`}
          >
            <Label htmlFor={id} className={`${type}-label`}>
              {label}
              {field.isRequired && "*"}
            </Label>
            <Input
              type="email"
              name={`${id}`}
              className={`${type}-input`}
              id={id}
              value={value}
              placeholder={field.placeholder}
              onChange={handleInput}
            />
            <ErrorText className="error-text">
              You must complete this field
            </ErrorText>
          </LabelAndInput>
        );
      /**
       * Phone Field
       */
      case "phone":
        return (
          <LabelAndInput
            className={`${type}-field${errors[id] ? " error" : ""}${
              isHidden ? " hide" : ""
            } ${field.cssClass}`}
          >
            <Label htmlFor={`${id}`} className={`${type}-label`}>
              {label}
              {field.isRequired && "*"}
            </Label>
            <Input
              type="tel"
              name={`${id}`}
              className={`${type}-input`}
              id={`${id}`}
              value={value}
              placeholder={field.placeholder}
              onChange={handleInput}
            />
            <ErrorText className="error-text">
              You must complete this field
            </ErrorText>
          </LabelAndInput>
        );
      case "address":
        return (
          <AddressField className={`address-field${isHidden ? " hidden" : ""}`}>
            {field.inputs.map((input, index) => {
              if (input.isHidden) return;

              const sub_label = input.label
                .split("/")[0]
                .toLowerCase()
                .trim();
              const sub_id = input.id;

              if (input.label === "Street Address") {
                return (
                  <LabelAndInput
                    key={input.id}
                    className={`${sub_label}-${type}-field ${errors[
                      `${type}_${sub_id}`
                    ] && "error"}`}
                  >
                    <Label
                      htmlFor={`${sub_label}_${id}`}
                      className={`${sub_label}-${type}-label`}
                    >
                      {input.label}
                      {field.isRequired && "*"}
                    </Label>
                    <Input
                      type="text"
                      name={`${type}_${sub_id}`}
                      className={`${sub_label}-${type}-input`}
                      id={`${sub_label}_${id}`}
                      value={value[index]}
                      placeholder={`${
                        input.placeholder ? input.placeholder : ""
                      }`}
                      onChange={handleInput}
                    />
                    <ErrorText className="error-text">
                      You must complete this field
                    </ErrorText>
                  </LabelAndInput>
                );
              }
              if (input.label === "City") {
                return (
                  <LabelAndInput
                    key={input.id}
                    className={`${sub_label}-${type}-field ${errors[
                      `${type}_${sub_id}`
                    ] && "error"}`}
                  >
                    <Label
                      htmlFor={`${sub_label}_${id}`}
                      className={`${sub_label}-${type}-label`}
                    >
                      City{field.isRequired && "*"}
                    </Label>
                    <Input
                      type="text"
                      name={`${type}_${sub_id}`}
                      className={`${sub_label}-${type}-input`}
                      id={`${sub_label}_${id}`}
                      value={value[index]}
                      placeholder={`${
                        input.placeholder ? input.placeholder : ""
                      }`}
                      onChange={handleInput}
                    />
                    <ErrorText className="error-text">
                      You must complete this field
                    </ErrorText>
                  </LabelAndInput>
                );
              }
              if (input.label === "State / Province") {
                return (
                  <LabelAndInput
                    key={input.id}
                    className={`${sub_label}-${type}-field${
                      errors[`${type}_${sub_id}`] ? " error" : ""
                    }${isHidden ? " hide" : ""}`}
                  >
                    <Label
                      htmlFor={`${sub_label}_${id}`}
                      className={`${sub_label}-${type}-label`}
                    >
                      State{field.isRequired && "*"}
                    </Label>

                    <Select
                      name={`${type}_${sub_id}`}
                      className={`${sub_label}-${type}-input`}
                      id={`${sub_label}_${id}`}
                      onChange={handleInput}
                    >
                      {US_States.map((state, index) => (
                        <Option
                          key={index}
                          value={state}
                          className={index === 0 ? "placeholder" : ""}
                        >
                          {state}
                        </Option>
                      ))}
                    </Select>
                    <ErrorText className="error-text">Required</ErrorText>
                  </LabelAndInput>
                );
              }
              if (input.label === "ZIP / Postal Code") {
                return (
                  <LabelAndInput
                    key={input.id}
                    className={`${sub_label}-${type}-field ${errors[
                      `${type}_${sub_id}`
                    ] && "error"}`}
                  >
                    <Label
                      htmlFor={`${sub_label}_${id}`}
                      className={`${sub_label}-${type}-label`}
                    >
                      Zip Code{field.isRequired && "*"}
                    </Label>
                    <Input
                      type="text"
                      name={`${type}_${sub_id}`}
                      className={`${sub_label}-${type}-input`}
                      id={`${sub_label}_${id}`}
                      value={value[index]}
                      placeholder={`${input.placeholder}`}
                      onChange={handleInput}
                    />
                    <ErrorText className="error-text">
                      You must complete this field
                    </ErrorText>
                  </LabelAndInput>
                );
              }
            })}
          </AddressField>
        );
      case "textarea":
        return (
          <LabelAndInput
            className={`${type}-field${errors[id] ? " error" : ""}${
              isHidden ? " hide" : ""
            } ${field.cssClass}`}
          >
            <Label htmlFor={id} className={`${type}-label`}>
              {label}
            </Label>
            <TextArea
              name={id}
              id={id}
              value={value}
              className={`${type}-input`}
              onChange={handleInput}
            />
            <ErrorText className="error-text">
              You must complete this field
            </ErrorText>
          </LabelAndInput>
        );
      case "radio":
        const choices = JSON.parse(field.choices);
        return (
          <RadioSection
            className={`${errors[id] ? "error" : ""}${isHidden ? " hide" : ""}`}
          >
            <Label className={`${type}-field`}>{label}</Label>
            {choices.map(choice => {
              return (
                <RadioFieldSet key={choice.value}>
                  <Radio
                    type="radio"
                    id={`${id}_${choice.value.toLowerCase()}`}
                    name={`${id}`}
                    className={`${type}-input`}
                    value={choice.value}
                    onChange={handleInput}
                    checked={value === choice.text}
                  />
                  <RadioLabel htmlFor={`${id}_${choice.value.toLowerCase()}`}>
                    {choice.text}
                  </RadioLabel>
                </RadioFieldSet>
              );
            })}
            <ErrorText className="error-text">
              You must complete this field
            </ErrorText>
          </RadioSection>
        );

      case `checkbox`:
        // const checkboxes = JSON.parse(field.choices);
        const checkboxes = field.inputs;
        return (
          <CheckboxSection
            className={`${errors[id] ? "error" : ""}${isHidden ? " hide" : ""}`}
          >
            <Label className={`${type}-field`}>{label}</Label>
            {checkboxes.map((choice, index) => {
              return (
                <CheckboxFieldSet key={index}>
                  <Checkbox
                    type="checkbox"
                    id={`${choice.id}`}
                    value={`${choice.label}`}
                    name={`checkbox_${choice.id}`}
                    className={`${type}-input`}
                    onChange={handleInput}
                    checked={value.includes(choice.label)}
                  />
                  <CheckboxLabel htmlFor={`${choice.id}`}>
                    {choice.label}
                  </CheckboxLabel>
                </CheckboxFieldSet>
              );
            })}
            <ErrorText className="error-text">
              You must complete this field
            </ErrorText>
          </CheckboxSection>
        );

      case "select":
        const options = JSON.parse(field.choices);
        return (
          <LabelAndInput
            className={`${type}-field${errors[id] ? " error" : ""}${
              isHidden ? " hide" : ""
            } ${field.cssClass}`}
          >
            <Label htmlFor={id} className={`${type}-field`}>
              {label}
            </Label>
            <select id={`${id}`} name={`${id}`} onChange={handleInput}>
              {options.map(choice => {
                return (
                  <option
                    id={`${id}_${choice.value.toLowerCase()}`}
                    key={choice.value}
                    value={choice.value}
                  >
                    {choice.text}
                  </option>
                );
              })}
            </select>
          </LabelAndInput>
        );
    }
    return "";
  };

  return renderField(field);
};

export default GFField;
