import React, { useState, useEffect } from "react";
import tw, { styled } from "twin.macro";
import Button from "../PageComponents/Button";
import { ReactComponent as Facebook } from "../../images/logos/facebook.svg";
import { ReactComponent as Instagram } from "../../images/logos/instagram.svg";
import { ReactComponent as Twitter } from "../../images/logos/twitter.svg";

const Icon = styled.button`
  text-align: center;
  border: 1.13px solid var(--color-offWhite);
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-right: 5px;
  padding: 2px;
  transition: background-color 0.25s ease;

  ${tw`
  rounded-full flex items-center justify-center bg-transparent
  `};

  svg {
    transition: stroke 0.25s ease;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background-color: #fff;

    svg {
      stroke: var(--color-offBlack);
    }
  }

  @media (min-width: 768px) {
    height: 30px;
    width: 30px;
    margin-right: 9px;
    padding: 6px 4px 4px;
  }
`;

const SocialIcon = ({ icon, large }) => {
  const [iconContent, setIconContent] = useState(null);
  useEffect(() => {
    switch (icon) {
      case "facebook":
        setIconContent(
          <a
            href="https://www.facebook.com/eachstephome"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook />
          </a>
        );
        break;
      case "instagram":
        setIconContent(
          <a
            href="https://www.instagram.com/eachstephome/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram />
          </a>
        );
        break;
      case "twitter":
        setIconContent(
          <a
            href="https://twitter.com/eachstephome"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter />
          </a>
        );
        break;
      default:
        break;
    }

    return () => {};
  }, [icon]);
  return (
    <>
      {large ? (
        <Button style="light socials">{iconContent}</Button>
      ) : (
        <Icon>{iconContent}</Icon>
      )}
    </>
  );
};
export default SocialIcon;
