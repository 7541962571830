import { styled } from 'twin.macro';

const Select = styled.select`
  border: none;
  font-size: 12px;
  height: 1.55rem;
  color: var(--color-grey);
  width: 100%;

  border-bottom: 2px solid rgba(124, 135, 149, 0.3);

  &:focus {
    outline: 2px solid rgba(248, 62, 94, 0.5);
    border-radius: 5px;
  }

  @media (min-width: 768px) {
    height: 2rem;
    font-size: 15px;
  }

  @media (min-width: 1024px) {
    height: 2.4rem;
  }
`;

export default Select;