import React from "react";

import GFField from "./GFField";

const Fields = ({ field_array, errors, formValues, handleInput }) => {
  return (
    <>
      {field_array.map((field, index) => {
        const isHidden =
          formValues[
            `${field.type}_${
              field.inputs
                ? field.inputs.find(input => !input.isHidden).id
                : field.id
            }`
          ].isHidden;
        let value;
        if (field.type === "checkbox") {
          value = field.inputs
            ? field.inputs.map(
                input =>
                  !input.isHidden &&
                  formValues[`${field.type}_${input.id}`].checked &&
                  formValues[`${field.type}_${input.id}`].value
              )
            : formValues[`${field.type}_${field.id}`].value;
        } else {
          value = field.inputs
            ? field.inputs.map(
                input =>
                  !input.isHidden &&
                  formValues[`${field.type}_${input.id}`].value
              )
            : formValues[`${field.type}_${field.id}`].value;
        }

        return (
          <GFField
            key={`${field.type}_${field.id}`}
            field={field}
            id={`${field.type}_${field.id}`}
            value={value}
            handleInput={handleInput}
            errors={errors}
            isHidden={isHidden}
          />
        );
      })}
    </>
  );
};

export default Fields;
