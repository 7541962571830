import { styled } from "twin.macro";

const FormTitle = styled.h3`
  text-align: center;
  font-weight: 600;

  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.63px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.01px;
  }

  @media (min-width: 1024px) {
    font-size: 43px;
    line-height: 52px;
    letter-spacing: -0.09px;
  }
`;

export default FormTitle;