import React, { useContext } from "react";

import Context from "../../context/Context";
import FormModal from '../PageComponents/Forms/FormModal';
import Button from "../PageComponents/Button";

const ConsentForm = () => {
  const { setModal } = useContext(Context);

  return (
    <FormModal>
      <div className="py-12">
        <p className="text-center max-w-2xl mx-auto">Please confirm that you have read the Sponsorship Guide before starting your application.</p>
        <div className="flex justify-center mt-10">
          <Button style="dark" onClick={() => setModal(true, "sponsor")}>
            I have read the Sponsorship Guide
          </Button>
        </div>
        <a className="block text-center text-grey underline text-sm mt-4" href="/get-involved/sponsor/">Return to Sponsorship Guide</a>
      </div>
    </FormModal>
  );
};

export default ConsentForm;
