import { styled } from 'twin.macro';

const ErrorText = styled.p`
  display: none;
  text-transform: uppercase;
  font-family: attribute-mono, monospace;
  color: var(--color-magenta);
  font-size: 10px;
  line-height: 1;
  letter-spacing: -0.1px;
  width: 100%;
  height: 0; // <- won't cause spacing issues between inputs

  @media (min-width: 768px) {
    padding-top: 2px;
    font-size: 12px;
    line-height: 17px;
  }
`;

export default ErrorText;