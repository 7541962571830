import React, { useContext, useState, useEffect } from "react";

import FormModalWrapper from './FormModalWrapper';
import FormModalBody from './FormModalBody';
import CloseButton from './CloseButton';
import Context from '../../../context/Context';
import { ReactComponent as CloseIcon } from '../../../images/ICON_GREY_CLOSE.svg';

const FormModal = ({ children }) => {
  const { modalOpen, setModal } = useContext(Context);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflowY = "hidden";
      setVisible(true);
    }, 125);

    return () => {
      document.body.style.overflowY = "";
    };
  }, []);

  return (
    <FormModalWrapper className={visible ? "open" : ""}>
      <button className="fixed inset-0 w-full h-full" onClick={() => setModal(false, null, null)}></button>
      <FormModalBody>
        <CloseButton onClick={() => setModal(false, null, null)}>
          <CloseIcon />
        </CloseButton>

        {children}
      </FormModalBody>
    </FormModalWrapper>
  );
};

export default FormModal;
