import { styled } from "twin.macro";

const FormWrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  transition: all 0.5s ease;
  padding: 2rem 1rem;

  &.open {
    z-index: 10;
    opacity: 1;
    overflow-y: scroll;
    pointer-events: all;
  }

  @media (min-width: 768px) {
    padding: 7rem 64px;
  }
`;

export default FormWrapper;