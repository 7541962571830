import { styled } from 'twin.macro';

const Label = styled.label`
  display: block;
  font-weight: 600;
  width: 100%;

  font-size: 11px;
  line-height: 13px;
  line-height: -0.1px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.2px;
    margin-bottom: 10px;
  }
`;

export default Label;