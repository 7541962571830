import tw, { styled } from 'twin.macro';

const CheckboxSection = styled.div`
  ${tw`
    px-2
  `};
  transition: all 0.2s ease-out;
  width: 100%;
  position: relative;
  // margin-top: 25px;

  &.hide {
    height: 0;
    opacity: 0;
    margin: 0 !important;
    transition: none;
  }

  &.error .error-text {
    display: block;
    border-top: 1px solid red;
    padding-top: 4px;
  }

  // @media (min-width: 768px) {
  //   margin-top: 40px;
  // }
`;

export default CheckboxSection;