import { styled } from "twin.macro";

const CloseButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  position: absolute;
  cursor: pointer;

  top: 10px;
  right: 10px;

  svg {
    width: 15px;
    height: 15px;
    fill: var(--color-grey);
  }

  @media (min-width: 768px) {
    top: 20px;
    right: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export default CloseButton;