import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import GFForm from '../PageComponents/Forms/GFForm';
import FormModal from '../PageComponents/Forms/FormModal';

const SponsorForm = () => {
  const { allGfForm } = useStaticQuery(graphql`
    {
      allGfForm(filter: {formId: {eq: 4}}) {
        edges {
          node {
            formId
            title
            description
            slug
            apiURL
            confirmations {
              id
              name
              isDefault
              type
              message
              url
              queryString
            }
            formFields {
              type
              label
              placeholder
              cssClass
              id
              inputs {
                name
                label
                inputType
                choices {
                  text
                  value
                }
                isHidden
                id
                placeholder
              }
              isRequired
              addressType
              checkboxLabel
              choices
              conditionalLogic
              defaultValue
            }
          }
        }
      }
    }
  `);

  return (
    <FormModal>
      <GFForm
        id={4}
        data={allGfForm.edges[0].node}
        hasTitle={true}
        hasDescription={true}
        hasFooter={true}
        hasSections={true}
        clearOnSuccess={true}
      />
    </FormModal>
  );
};

export default SponsorForm;
