import { styled } from 'twin.macro';

const Checkbox = styled.input`
  margin: 0 5px 5px 0;

  @media (min-width: 768px) {
    margin: 0 10px 10px 0px;
    height: 14px;
    width: 14px;
  }

  @media (min-width: 1024px) {
    height: 20px;
    width: 20px;
  }
`;

export default Checkbox;