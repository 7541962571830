import React from "react";

import GFField from "./GFField";
import FieldSet from "./FieldSet";

const FieldsBySections = ({ field_array, errors, formValues, handleInput }) => {
  // set up fields by section / fieldsets
  // and set up the initial field state
  let field_sections = [],
    section_count = 0;

  field_array.forEach((field, index) => {
    // start a new section if the next field type is 'section'
    if (index > 0 && field.type === "section") section_count++;
    // make sure we've initialized an array
    if (field_sections[section_count] === undefined)
      field_sections[section_count] = [];

    field_sections[section_count].push(field);
  });

  return (
    <>
      {field_sections.map((section, index) => {
        return (
          <FieldSet key={index}>
            {/* Renders field by the type set by Gravity Forms */}
            {section.map(field => {
              const form_value_id = `${field.type}_${
                field.inputs
                  ? field.inputs.find(input => !input.isHidden).id
                  : field.id
              }`;
              const isHidden = formValues[form_value_id].isHidden;

              let value;
              if (field.type === "checkbox") {
                value = field.inputs
                  ? field.inputs.map(
                      input =>
                        !input.isHidden &&
                        formValues[`${field.type}_${input.id}`].checked &&
                        formValues[`${field.type}_${input.id}`].value
                    )
                  : formValues[`${field.type}_${field.id}`].value;
              } else {
                value = field.inputs
                  ? field.inputs.map(
                      input =>
                        !input.isHidden &&
                        formValues[`${field.type}_${input.id}`].value
                    )
                  : formValues[`${field.type}_${field.id}`].value;
              }

              return (
                <GFField
                  key={`${field.type}_${field.id}`}
                  field={field}
                  id={`${field.type}_${field.id}`}
                  value={value}
                  handleInput={handleInput}
                  errors={errors}
                  isHidden={isHidden}
                />
              );
            })}
          </FieldSet>
        );
      })}
    </>
  );
};

export default FieldsBySections;
