import { styled } from "twin.macro";

const FieldSet = styled.div`
  border-top: 1px solid rgba(124, 135, 149, 0.3);
  padding: 1rem 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: 768px) {
    padding: 2rem 3rem;
  }
  @media (min-width: 1024px) {
    padding: 4rem 6rem;
  }
`;

export default FieldSet;