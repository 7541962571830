import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import tw, { styled } from "twin.macro";
import { useMediaQuery } from "react-responsive";
// import Context from "../context/Context";
import { ReactComponent as MenuIcon } from "../images/ICON_GREY_MENU.svg";
import { ReactComponent as SearchIcon } from "../images/ICON_GREY_SEARCH.svg";
import { ReactComponent as CloseIcon } from "../images/ICON_GREY_CLOSE.svg";
import HomeStackedLogo from "../images/logos/STACKED_LIGHT.svg";
import HomeMenuLogo from "../images/logos/MENU_DARK.svg";
import MobileNav from "./Layout/MobileNav";
import { useLocation } from "@reach/router";

const Header = styled.header`
  ${tw`
    bg-white z-10
  `}

  transition: all 0.5s ease;
  position: sticky;
  top: 0;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);

  &.home {
    ${tw`
    bg-transparent fixed top-0 left-0 right-0 text-white items-start
  `}
    box-shadow: none;

    // .home {
    //   padding-top: 2rem;
    // }

    .logo-small {
      transform: translateY(-200px);
    }

    &.alert {
      top: 3rem;

      @media (max-width: 768px) {
        top: 3.5rem;
      }
    }

    &.scrolled {
      top: 0;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
        0 2px 4px 0 rgba(0, 0, 0, 0.19);

      .home {
        color: #0f1e2d;
      }

      .logo-large {
        transform: translateY(-200px);
      }

      .logo-small {
        transform: translateY(0);
      }
    }

    .logo-large {
      position: absolute;
      max-height: 65px;
      height: auto;
    }

    @media (min-width: 768px) {
      .logo-large {
        width: 190px;
        max-height: initial;
      }
    }

    @media (min-width: 1024px) {
      .logo-large {
        width: 210px;
      }
    }
  }
`;

const InnerHeader = styled.div`
  ${tw`
    flex flex-row items-center justify-between
  `}

  svg {
    fill: ${props => (props.isHome ? "#fff" : "#000")};
  }

  font-weight: 700;
  font-style: normal;

  padding: 1.5rem;

  &.home {
    ${tw`text-white`}
  }

  @media (min-width: 1024px) {
    ${tw`
    items-center
  `}
    max-width: 1440px;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    padding: 20px 64px;

    &.home {
      padding: 20px 64px;
    }
  }

  @media (min-width: 1024px) {
    ${tw` items-center `}
    padding: 20px 75px;
  }
`;

const HeaderTitle = styled(Link)`
  position: relative;
  display: block;
  height: 100%;
  width: 100%;

  img {
    transition: all 0.5s ease;
    width: 161px;
    height: 22px;

    @media (min-width: 768px) {
      width: 226px;
      height: 31px;
    }

    @media (min-width: 1024px) {
      width: 246px;
      height: 38px;
    }
  }
`;

const Nav = styled.nav`
  ${tw`
   flex flex-row items-center justify-center
  `}
`;

const HeaderButtons = styled.div`
  ${tw`
    flex flex-row items-center justify-end content-center
  `}
  @media (min-width: 1024px) {
    padding-top: 4px;
  }
`;
const NavLink = styled(Link)`
  ${tw`
    hover:text-gray-500
  `}
  font-size: 18px;
  line-height: 48px;
  letter-spacing: -0.17px;
  color: inherit;
  text-decoration: none;
  margin-right: 20px;
  border-bottom: 2px solid transparent;
  transition: all 0.25s ease;
  width: max-content;

  &:hover {
    color: var(--color-magenta);
    text-decoration: underline;
  }

  &.active {
    color: var(--color-magenta);
    text-decoration: underline;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const Button = styled.button`
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;

  &:focus {
    outline: 1px solid transparent;
  }

  svg {
    fill: var(--color-offBlack);
    width: 18px;
    height: 17px;
  }

  &.home {
    svg {
      fill: var(--color-offWhite);
    }
  }
  @media (min-width: 768px) {
    svg {
      width: 21px;
      height: 20px;
    }
  }

  @media (min-width: 1150px) {
    display: none;
  }
`;

const DonateAndSearch = styled.aside`
  ${tw`
    flex-row items-center justify-between
  `}
`;

const LangButton = styled.button`
  ${tw`
    text-white border-0 p-0 bg-transparent text-center
  `}
  font-family: attribute-mono, monospace;
  margin-right: 16px;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.25px;
  display: ${props => (props.isHome ? "inline-block" : "none")};
  color: inherit;

  @media (min-width: 768px) {
    display: inline-block;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.34px;
  }
`;

const DonateButton = styled.button`
  font-family: attribute-mono, monospace;
  text-align: center;
  cursor: pointer;
  ${tw`
    text-white border-0
  `}

  text-transform: uppercase;
  background-color: var(--color-magenta);
  line-height: 0;
  font-size: 11.25px;
  padding: 7.5px 17px;
  border-radius: 15px;
  width: 75px;
  height: 30px;
  margin: 0 16px;

  @media (min-width: 768px) {
    font-size: 15px;
    letter-spacing: 0.34px;
    width: 100px;
    height: 40px;
    border-radius: 20px;
    margin: 0 20px;
  }
`;

const SearchButton = styled.button`
  ${tw`
    bg-transparent rounded-none border-0 p-0
  `}
  display: ${props => (props.isHome ? "inline-block" : "none")};

  svg {
    height: 17px;
    width: 17px;
    fill: #fff;
    vertical-align: middle;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    display: inline-block;
  }
`;

const Notification = styled.div`
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-family: attribute-mono, monospace;
  text-transform: uppercase;
  background-color: var(--color-offWhite);
  color: var(--color-offBlack);
  z-index: 2;
  transform: translateY(-100%);
  transition: transform 0.5s ease;

  a {
    color: var(--color-magenta);
    cursor: pointer;
  }

  &.open-true {
    transform: translateY(0%);
  }

  padding: 10px 20px;
  font-size: 10px;
  line-height: 14px;

  @media (min-width: 768px) {
    height: 50px;
    font-size: 13px;
    line-height: 18px;
    padding: 16px 0;
  }

  @media (min-width: 1024px) {
    font-size: 15px;
    line-height: 21px;
    padding: 13px 0;
  }
`;

// const NotificationCTA = styled.a`
//   color: var(--color-magenta);
//   cursor: pointer;
// `;

// NotificationCTA.defaultProps = { target: props => props.target };

const NotificationSpan = styled.span`
  p {
    display: inline-block;
    font-size: 0.7rem;
    font-weight: 300;
    padding: 0 0 0 1rem;
    margin-bottom: 6px;
  }

  @media (min-width: 640px) {
    p {
      font-size: 0.8rem;
    }
  }

  @media (min-width: 768px) {
    p {
      font-size: 1rem;
    }
  }
`;

const CloseNotification = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  position: absolute;
  padding: 0;
  height: 0;

  right: 20px;
  top: 8px;
  svg {
    height: 8px;
    width: 8px;
  }

  @media (min-width: 768px) {
    top: 18px;
    svg {
      height: 10px;
      width: 10px;
    }
  }

  @media (min-width: 1024px) {
    svg {
      height: 11px;
      width: 11px;
    }
  }
`;

const HeaderComponent = ({ alert }) => {
  const data = useStaticQuery(graphql`
    {
      allWordpressMenusMenusItems(filter: { slug: { eq: "primary" } }) {
        nodes {
          slug
          items {
            title
            slug
          }
        }
      }
    }
  `);

  const isMobile = useMediaQuery({ query: "(max-width: 1150px)" });
  const location = useLocation();
  // const { currentLanguage, setCurrentLanguage } = useContext(Context);
  const [isHome] = useState(
    location.pathname === "/" || location.pathname.includes("donate")
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [navList, setNavList] = useState(
    data?.allWordpressMenusMenusItems?.nodes[0]?.items
  );
  const [scrolled, setScrolled] = useState(false);

  const handleClickNotification = e => {
    setAlertOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setAlertOpen(true);
    }, 8000);

    if (isHome) {
      setInterval(() => {
        if (window.userIsScrolling) {
          const { scrollY } = window;
          if (scrollY >= 48) {
            setScrolled(true);
          } else if (scrollY < 48) {
            setScrolled(false);
          }

          window.userIsScrolling = false;
        }
      }, 250);
    }

    return () => {
      setAlertOpen(false);
    };
  }, []);

  return (
    <>
      {isHome && alert && (
        <Notification className={`open-${alertOpen}`}>
          <NotificationSpan dangerouslySetInnerHTML={{ __html: alert?.copy }} />
          {/* <NotificationCTA
            href={alert?.url?.url || "/"}
            target={alert?.url?.target}
          >
            {alert?.url?.title}
          </NotificationCTA> */}
          <CloseNotification onClick={handleClickNotification}>
            <CloseIcon />
          </CloseNotification>
        </Notification>
      )}
      <Header
        className={`${isHome ? "home" : ""} ${
          alertOpen && alert ? "alert" : ""
        } ${scrolled ? "scrolled" : ""}`}
      >
        <InnerHeader
          className={`${isHome ? "home" : ""} ${
            alertOpen && alert ? "alert" : ""
          }`}
          isHome={isHome}
        >
          <div className="w-2/5 lg:w-1/6 lg:pr-8 xl:w-1/5">
            <HeaderTitle to="/">
              {isHome && (
                <>
                  <img
                    src={HomeStackedLogo}
                    className={`logo-large`}
                    alt="Each. Step. Home. Logo"
                  />
                  <img
                    src={HomeMenuLogo}
                    className={`logo-small`}
                    alt="Each. Step. Home. Logo"
                  />
                </>
              )}
              {!isHome && (
                <img
                  src={HomeMenuLogo}
                  className={`logo-small`}
                  alt="Each. Step. Home. Logo"
                />
              )}
            </HeaderTitle>
          </div>

          {!isMobile && (
            <div className="flex-1">
              <Nav>
                {navList.map((nav, index) => {
                  const locationSlug = location.pathname.split("/")[1];
                  return (
                    <NavLink
                      activeClassName={"active"}
                      className={locationSlug === nav.slug ? "active" : ""}
                      to={`/${nav.slug}/`}
                      key={index}
                    >
                      {nav.title}
                    </NavLink>
                  );
                })}
              </Nav>
            </div>
          )}

          <div className="w-auto">
            <HeaderButtons>
              {/* <LangButton
                isHome={isHome}
                onClick={() =>
                  currentLanguage === "en"
                    ? setCurrentLanguage("esp")
                    : setCurrentLanguage("en")
                }
              >
                {currentLanguage === "en" ? "ESP" : "EN"}
              </LangButton>
              <SearchButton isHome={isHome} onClick={() => alert("Todo: Search")}>
                <SearchIcon />
              </SearchButton> */}
              <a href="https://eachstephome.donorsupport.co/-/XYWQRRQB">
                <DonateButton>Donate</DonateButton>
              </a>
              <Button
                onClick={() => setMenuOpen(true)}
                className={isHome ? "home" : ""}
              >
                <MenuIcon />
              </Button>
            </HeaderButtons>
          </div>
        </InnerHeader>
      </Header>
      <MobileNav
        open={menuOpen}
        list={navList}
        onClick={() => setMenuOpen(false)}
      />
    </>
  );
};

export default HeaderComponent;
