import { styled } from 'twin.macro';

const Input = styled.input`
  display: block;
  border: none;
  border-bottom: 2px solid rgba(124, 135, 149, 0.3);
  width: 100%;
  color: var(--color-grey);
  background: none;

  &::placeholder {
    opacity: 0.3;
  }

  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.26px;
  font-family: inherit;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 29px;
    letter-spacing: -0.36px;

    &.city,
    &.phone {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.41px;
  }
`;

export default Input;