import React, { useState, useEffect, useContext } from "react";
import tw, { styled } from "twin.macro";
import Context from "../../context/Context";
import { ReactComponent as CloseIcon } from "../../images/ICON_GREY_CLOSE.svg";
import SponsorForm from "./SponsorForm";
import ConsentForm from "./ConsentForm";
import VolunteerForm from "./VolunteerForm";

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.25s ease;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;

  section {
    transform: translateY(20px);
  }

  &.open {
    opacity: 1;
    pointer-events: all;

    section {
      transform: translateY(0px);
    }
  }
`;

const Modal = styled.section`
  background-color: #ffffff;
  position: absolute;
  transition: transform 0.25s ease;

  top: 164px;
  left: 20px;
  right: 20px;
  padding: 40px 50px 50px;
  text-align: center;

  @media (min-width: 768px) {
    left: 64px;
    right: 64px;
    padding: 60px;
  }
`;

const CloseButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  position: absolute;
  cursor: pointer;

  top: 10px;
  right: 10px;

  svg {
    width: 15px;
    height: 15px;
    fill: var(--color-grey);
  }

  @media (min-width: 768px) {
    top: 20px;
    right: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const Message = styled.div`
  strong,
  b {
    font-weight: bold;
  }

  h1,
  h2,
  h3 {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.63px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.27px;
  }
  @media (min-width: 768px) {
    h1,
    h2,
    h3 {
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -1px;
    }

    p {
      font-size: 19px;
      line-height: 29px;
    }
  }
`;

const FeedbackModal = ({ message, onClick }) => {
  const [open, setOpen] = useState(false);

  const { modalType, modalOpen, modalData } = useContext(Context);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
      document.body.style.overflowY = "hidden";
    }, 125);
    return () => {
      setOpen(false);
      document.body.style.overflowY = "";
    };
  }, []);
  return (
    <>
      {modalType === "consent" && <ConsentForm />}
      {modalType === "sponsor" && <SponsorForm />}
      {modalType === "volunteer" && <VolunteerForm />}
      {modalType === "feedback" && (
        <Background className={open ? "open" : ""}>
          <Modal>
            <CloseButton
              onClick={() => {
                setOpen(false);
                onClick();
              }}
            >
              <CloseIcon />
            </CloseButton>
            <Message dangerouslySetInnerHTML={{ __html: modalData }} />
          </Modal>
        </Background>
      )}
    </>
  );
};

export default FeedbackModal;
