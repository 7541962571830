const gen4 = () => {
  return Math.random().toString(16).slice(-4)
}

const uniqueId = (prefix) => {
  return (prefix || '').concat([
    gen4(),
    gen4(),
    gen4(),
    gen4(),
    gen4(),
    gen4(),
    gen4(),
    gen4()
  ].join(''))
}

export default uniqueId;