import { styled } from 'twin.macro';

const CHeckboxLabel = styled.label`
  font-size: 11px;
  line-height: 13px;
  font-family: inherit;
  margin: 0 0 5px;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 13px;
    line-height: 16px;
    margin: 0 0 10px;
  }

  @media (min-width: 1024px) {
    font-size: 15px;
    line-height: 18px;
  }
`;

export default CHeckboxLabel;