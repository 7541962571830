import tw, { styled } from "twin.macro";

const SectionTitle = styled.p`
  ${tw`
    px-2
  `};
  font-weight: 600;
  width: 100%;

  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.14px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 29px;
    letter-spacing: -0.18px;
  }

  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.56px;
  }
`;

export default SectionTitle;