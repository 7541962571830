import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Context from "../../context/Context";
import { useLocation } from "@reach/router";
import Header from "../Header";
import Footer from "../Footer";
import Modal from "../PageSections/Modal";
import "./reset.css";
import "../../styles/global.scss";

const Layout = ({ alert, children }) => {
  const location = useLocation();

  const { modalOpen, setModal } = useContext(Context);

  // ESC button closes modals
  useEffect(() => {
    // this will be used globally for functions
    // that need to hook into scroll events
    document.addEventListener("scroll", e => {
      window.userIsScrolling = true;
    });

    // ESC button closes modals
    document.addEventListener("keyup", e => {
      if (modalOpen && (e.keyCode === 27 || e.code === 27)) {
        // keyCode is deprecated https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        setModal(false, null, null);
      }
    });
  });

  return (
    <>
      <Helmet>
        <script>
          {`(function(w,d,s,n,a){if(!w[n]){var l='call,catch,on,once,set,then,track'
            .split(','),i,o=function(n){return'function'==typeof n?o.l.push([arguments])&&o
            :function(){return o.l.push([n,arguments])&&o}},t=d.getElementsByTagName(s)[0],
            j=d.createElement(s);j.async=!0;j.src='https://cdn.fundraiseup.com/widget/'+a;
            t.parentNode.insertBefore(j,t);o.s=Date.now();o.v=4;o.h=w.location.href;o.l=[];
            for(i=0;i<7;i++)o[l[i]]=o(l[i]);w[n]=o}
            })(window,document,'script','FundraiseUp','AKYEMMJH');`}
        </script>
      </Helmet>
      <Header location={location} alert={alert} />
      {children}
      <Footer />
      {modalOpen && <Modal />}

      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-TGNJ65S"
          height="0"
          width="0"
          style={{
            display: "none",
            visibility: "hidden"
          }}
        ></iframe>
      </noscript>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
